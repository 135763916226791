/* You can add global styles to this file, and also import other style files */
.badge-novo, .bg-novo {
  color: #fff !important;
  background: #DE6F00 !important;
}
.badge-preparo, .bg-preparo {
  color: #fff !important;
  background: #ffc107 !important;
}
.badge-pronto, .bg-pronto {
  color: #fff !important;
  background: #3abaf4 !important;
}
.badge-entrega, .bg-entrega{
  color: #fff !important;
  background: #54ca68 !important;
}
.badge-finalizado, .bg-finalizado {
  color: rgb(133, 133, 133) !important;
  background: #e3eaef !important;
}
.badge-cancelado, .bg-cancelado {
  color:#DE6F00 !important;
  background-color: #e3eaef;
}
.badge-orders {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}


.blink_me {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    background:#DE6F00;
  }
}

.cursor-pointer
{
  cursor: pointer;
}

.selected-item
{
  background-color: #DE6F00;
  color: #fff;
  list-style: none;
  width: 100%;
}
