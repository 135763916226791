/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJmaWxlIjoiY3VzdG9tLmNzcyIsInNvdXJjZXMiOlsiY3VzdG9tLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiJ9 */

/*# sourceMappingURL=custom.css.map */

/*

1 - LISTA DE PEDIDOS
2 - MODAL UPLOAD
3 - MODAL TICKET
4 - MODAL PRODUCT
5 - VIEWS DIVS MIN
6 - FORMS CUSTOM
7 - LISTA DE PRODUTOS E COMPLEMENTOS
8 - SWITCH NORMAL
9 - SWITCH TEXT
10 - CARDS AJUSTES
11 - Disabled Select
12 - MENUS
13 - PLANOS ASSINATURA
14 - AJUSTES GERAIS
*/

/*----- DEFAULT --------*/

:root{
  --bg-switch: #ef8821;
  --bg-pause: #f3f3f3;
  --color-pause: #c0c0c0;
  --color-primary-clickmix: #DE6F00;
  --color-primary-dark-clickmix: #bd5e00;
  --color-dark-clickmix: #212529;
  --color-gray-clickmix: #6c757d;

  --color-bg-success-light: #d1fae4;
  --color-bg-primary-light: #fbe5d0;

  --color-bg-gray: #f6f6f6;
  --color-bg-gray-light: #f9f8f7 ;
  --color-bg-gray-light-2: #f0eeea ;
  --color-bg-info-light: #ceeefc;
  --color-bg-warning-light: #fff9da ;

}

.bg-gray-light-01{
  background: var(--color-bg-gray-light)!important;
}
.bg-gray-light-02, .badge-gray-light-02{
  background: var(--color-bg-gray-light-2)!important;
}
.bg-light-success, .badge-light-success{
  background: var(--color-bg-success-light)!important;
}
.bg-light-info, .badge-light-info{
  background: var(--color-bg-info-light)!important;
}
.bg-light-warning, .badge-light-warning{
  background: var(--color-bg-warning-light)!important;
}
.col-gray, .color-gray-01{
  color: var(--color-gray-clickmix);
}
.color-primary-clickmix{
  color: var(--color-primary-clickmix);
}
.color-gray-light-01{
  color: var(--color-bg-gray-light)!important;
}
.color-gray-light-02{
  color: var(--color-bg-gray-light-2)!important;
}
.color-light-success{
  color: var(--color-bg-success-light)!important;
}
.color-light-info{
  color: var(--color-bg-info-light)!important;
}


/*----- 00 DEFAULT ELEMENTS ---*/

.buttons-header-nav{
  gap: 1rem;
  display: flex;
  margin-bottom: 1rem;
}

/*----- 1 LISTA DE PEDIDOS */

.btn-icon-lg .fas,
.btn-icon-lg .far,
.btn-icon-lg .fab,
.btn-icon-lg .fal{
  font-size: 18px!important;
}

textarea .add-product-textarea {
  height: 120px !important;
}

.helpBadge1 {
  position: absolute;
  top: 1px;
  font-weight: 300;
  padding: 3px 6px;
  background: #ffc107;
  border-radius: 10px;
  color: #fff;
  margin-left: 10px;
}

.food-item-img .image-preview, #callback-preview {
  width: 200px!important;
  height:200px!important;
}


/*---- LIST MERCHANTS ----*/

.list-merchant-div  {
  color: #212529!important;
}
.list-merchant-div img{
  -webkit-box-shadow: 0 5px 15px 0 rgb(105 103 103 / 50%);
  box-shadow: 0 5px 15px 0 rgb(105 103 103 / 50%);
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.filterSidebar{
  background:#fff;
  position:fixed;
  height:100%;
  width:280px;
  top:70px;
  right:-280px;
  z-index:999;
  transition:0.3s ease-in;
  box-shadow:0 2px 5px rgba(0,0,0,0.16),0 2px 10px rgba(0,0,0,0.12)
}
.filterSidebar .filterPanelToggle{background:#EF8821;
  padding:10px 15px;
  color:#fff;
  position:absolute;
  top:30%;
  left:-40px;
  width:40px;
  border-radius:10px 0 0 10px
}
.showFilterSidebar{
  right:0
}
.filterSidebar .filterSidebar-body{
  position:relative;
  height:100%
}
.filterSidebar .filterSidebar-tab{
  display:flex
}
.filterSidebar .filterSidebar-tab .nav-item{
  width:33.33%;
  text-align:center
}
.filterSidebar .filterSidebar-tab .nav-item .nav-link{
  padding:15px 12px;
  color:#6a7a8c;
  border-bottom:3px solid transparent
}
.filterSidebar .setting-panel-header{display:block;
  padding:15px 20px;
  color:#212529;
  font-size:15px;
  border:1px solid #eae9e9;
  background:#e9ecef
}
.filterSidebar .rt-sidebar-last-ele{
  margin-bottom:70px !important
}



.merchant-filter-grid-plans{
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  text-align: center;
}
.merchant-filter-search .selectgroup-button{
  line-height: 25px;
  height: 26px;
  padding: 0 0.6rem;
}

.list-merchant-div .badge-order-status div{
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;

}
.list-merchant-div h6 .badge {
  font-size: 12px!important;
}
.list-merchant-div td{
  height: 80px!important;
}
.badge.badge-cancel{
  color: #cc170d;
  background-color: #5f5c5c27;
}

.list-merchant-div .badge.badge-pendente{
  background-color: #DE6F00;
  color: #fff;
  -webkit-animation: sk-scaleout 1.5s ease-in-out infinite;
  animation: sk-scaleout 1.5s ease-in-out infinite;
}

.list-clickmix .font-mix-01,
.list-merchant-div .order-font-1{
  font-size: 16px;
}
.list-clickmix .font-mix-02,
.list-merchant-div .order-font-2{
  font-size: 15px;
}
.list-merchant-div .payment-tag img{
  width: 28px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  margin-right: 3px;
}
.list-merchant-div .order-costumer {
  position: relative;
}

.list-merchant-div .order-costumer small,
.list-merchant-div .order-costumer a:hover small{
  font-size: 90%;
  color: rgb(44, 42, 41);
}

.list-merchant-div  .order-costumer a:hover{
  text-decoration: none;
  color: var(--color-primary-dark-clickmix)!important;
}
.list-merchant-div .order-costumer-name,
.list-merchant-div .order-costumer-phone{
  font-size: 12px!important;
  font-weight: 400;
  color: #60686f!important;
}
.list-merchant-div .order-trans-type{
  font-size: 14px;
}
.list-merchant-div .btn-group .btn{
  padding: 0;
  color: var(--color-primary-clickmix);
}
.list-merchant-div .btn-group .btn .fas{
  font-size: 19px;
}
.list-merchant-div .btn-group a.dropdown-item {
  cursor: pointer;
}
.list-merchant-div .btn-group .dropdown-item:focus,
.list-merchant-div .btn-group .dropdown-item:hover{
  color: var(--color-primary-clickmix)!important;
}
.list-merchant-div .dropleft .dropdown-toggle::before{
  display: none;
}
.list-merchant-div .dropleft small{
  font-size: 70%;
  color: #9e9e9e;
  padding-left: 35px;
}
.list-merchant-div .info-order-badge{
  font-size: 10px!important;
  background-color: rgba(255, 255, 255, 0.871);
  padding: 3px 5px!important;
  color: #000;
  margin: 0 0.6em;
}
.list-merchant-div .badge-new{
  background-color: rgba(252, 92, 0, 0.753)!important;
  color: #fff!important;
}
.list-merchant-div .badge-normal{
  background-color: rgba(201, 198, 192, 0.342)!important;
}
.list-merchant-div .badge-order-origem{
  font-size: 11px;
}
.bagde-resume-orders{
  font-size: 16px!important;
}
@media (max-width: 780px){
  .list-merchant-div .badge-order-origem small{
    display: none;
  }
}

/*---- LIST ORDERS -----*/


.header-report-resume .card-group-orders{
  display: flex;
  gap: .6rem;
  flex-wrap: wrap;
  align-items: stretch;
}

.header-report-resume .card-group-orders .card-report-orders{
  min-width: 250px;
}
.header-report-resume .card-group-orders>div{
  min-width: 180px;
  background: var(--color-bg-gray);
  padding: .7rem;
}
.header-report-resume .card-group-orders div label{
  color: var(--color-dark-clickmix);
  font-size: 12px;
}
.header-report-resume .card-group-orders>div>div p{
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 1.3;
}
.header-report-resume .card-group-orders .card-report-orders>div{
  display: flex;
  justify-content: space-around;
  color: var(--color-gray-clickmix);
}
.header-report-resume .card-group-orders>div>div p span{
  font-weight: 600;
  font-size: 19px;
  color: var(--color-gray-clickmix);
}


.list-orders-merchant-div  {
  color: #212529!important;
}

.list-orders-merchant-div .badge-order-status div{
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;

}
.list-orders-merchant-div h6 .badge {
  font-size: 12px!important;
}
.list-orders-merchant-div td{
  height: 80px!important;
}

.list-orders-merchant-div .col{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.list-orders-merchant-div .payment-tag .tooltip-inner{
  background-color: var(--color-primary-clickmix)!important;

}
.badge.badge-cancel{
  color: #cc170d;
  background-color: #5f5c5c27;
}

.list-orders-merchant-div .badge.badge-pendente{
  background-color: #DE6F00;
  color: #fff;
  -webkit-animation: sk-scaleout 1.5s ease-in-out infinite;
  animation: sk-scaleout 1.5s ease-in-out infinite;
}

.list-clickmix .font-mix-01,
.list-orders-merchant-div .order-font-1{
  font-size: 16px;
}
.list-clickmix .font-mix-02,
.list-orders-merchant-div .order-font-2{
  font-size: 15px;
}
.list-orders-merchant-div .payment-tag img{
  width: 28px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  margin-right: 3px;
}
.list-orders-merchant-div .order-costumer {
  position: relative;
}
.list-orders-merchant-div .order-costumer .badge-order-new {
  position: absolute;
  top: 50%;
  left: 10px;
  background-color: rgba(59, 165, 27, 0.87)!important;
  color: #fff;
}
.list-orders-merchant-div .order-costumer small,
.list-orders-merchant-div .order-costumer a:hover small{
  font-size: 90%;
  color: rgb(44, 42, 41);
}

.list-orders-merchant-div  .order-costumer a:hover{
  text-decoration: none;
  color: var(--color-primary-dark-clickmix)!important;
}
.list-orders-merchant-div .order-costumer-name,
.list-orders-merchant-div .order-costumer-phone{
  font-size: 12px!important;
  font-weight: 400;
  color: #60686f!important;
}
.list-orders-merchant-div .order-trans-type{
  font-size: 14px;
}
.list-orders-merchant-div .btn-group .btn{
  padding: 0;
  color: var(--color-primary-clickmix);
}
.list-orders-merchant-div .btn-group .btn .fas{
  font-size: 19px;
}
.list-orders-merchant-div .btn-group a.dropdown-item {
  cursor: pointer;
}
.list-orders-merchant-div .btn-group .dropdown-item:focus,
.list-orders-merchant-div .btn-group .dropdown-item:hover{
  color: var(--color-primary-clickmix)!important;
}
.list-orders-merchant-div .dropleft .dropdown-toggle::before{
  display: none;
}
.list-orders-merchant-div .dropleft small{
  font-size: 70%;
  color: #9e9e9e;
  padding-left: 35px;
}
.list-orders-merchant-div .info-order-badge{
  font-size: 10px!important;
  background-color: rgba(255, 255, 255, 0.871);
  padding: 3px 5px!important;
  color: #000;
  margin: 0 0.6em;
}
.list-orders-merchant-div .badge-new{
  background-color: rgba(252, 92, 0, 0.753)!important;
  color: #fff!important;
}
.list-orders-merchant-div .badge-normal{
  background-color: rgba(201, 198, 192, 0.342)!important;
}
.list-orders-merchant-div .badge-order-origem{
  font-size: 11px;
}
.bagde-resume-orders{
  font-size: 16px!important;
}
@media (max-width: 780px){
  .list-orders-merchant-div .badge-order-origem small{
    display: none;
  }
  .list-orders-merchant-div .col{
    padding-right: 6px;
    padding-left: 6px;
  }
}


/*================ LIST ORDERS MANAGER ================= */

/*---- ORDER MANAGER --- HEADER ----*/

.nav-link .badge-transparent {
  background-color: #00000015;
}

.nav-link i{
  font-size: 16px;
}

html, body {
  height: 100%;
}

.main-wrapper-order-manager{
  min-height: 100%;
  height: 100%;
}

.header-order-manager {
  z-index: 890;
  left: 0px;
  right: 0px;
  width: 100%;
}
.header-order-manager nav{
  align-items: center;
  height: 70px;
  box-shadow: 15px 9px 25px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.header-order-manager nav .order-manager-header-title{
  width: 270px;
  vertical-align: middle;
  font-size: 15px;
  color: var(--color-gray-clickmix);
  padding: 5px 15px 5px 30px;
  text-decoration: none;
}
.header-order-manager nav .order-manager-header-title img{
  height: 30px!important;
  margin-right: 8px;
}

/*---- ORDER MANAGER --- MAIN ----*/


.main-content-orders-manager{
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 90px;
  width: 100%;
  position: relative;
}
.main-content-orders-manager .nav-tabs-stick{
  position: fixed;
  top: 70px;
  right: 0;
  left: 0;
  padding: 15px 30px;
  background-color: var(--color-bg-gray);
  z-index: 880;
}
.tab-delivery-body{
  display: grid;
  grid-template-columns: minmax(230px, 260px) auto;
  gap: 1.5rem;
  width: 100%;
}

/*---- ORDER MANAGER --- LIST ----*/

.card-order-list{
  background-color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow:
    0 0.46875rem 2.1875rem rgb(90 97 105 / 10%),
    0 0.9375rem 1.40625rem rgb(90 97 105 / 10%),
    0 0.25rem 0.53125rem rgb(90 97 105 / 12%),
    0 0.125rem 0.1875rem rgb(90 97 105 / 10%);
}
.order-manager-list-card{
  height: 100%;
  overflow: hidden;
  outline: none;
}
.order-manager-list-card .list-status-top{
  background-color: var(--color-bg-gray-light-2);
  position: relative;
  padding: 3px 15px;
}
.order-manager-list-card .list-status-top .badge{
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--color-bg-primary-light);
  font-weight: 700;
  padding: 6px 8px;
  border: 1px solid #fff;
}



/* ORDER MANAGER --- INFO & DETAILS ---- */

.card-order-grid{
  display: grid;
  grid-template-columns: minmax(270px, 340px) auto;
}

.card-order-grid .body-order-info{
  background-color: var(--color-bg-gray-light);
  border-radius: 10px 0 0 10px;

}
.card-order-grid .body-order-info>div{
  padding: 0.8rem 1.3rem;
}

.card-order-grid .body-order-info .info-header-order{
  display: grid;
  grid-template-columns: minmax(70px, 90px) minmax(70px, 90px) minmax(70px, 90px);
  gap: 1rem;
  height: 85px;
  border-bottom: 1px solid var(--color-bg-primary-light);

}
.card-order-grid .body-order-info .info-header-order h5{
  font-size: 18px;
  color: #000;
}

.card-order-grid .body-order-info .info-order-customer h6{
  color: #000;
  margin-bottom: 15px;
}
.card-order-grid .body-order-info .info-order-customer .psoos{
  margin: 0px;
}


/* ORDER MANAGER --- DETAILS ---- */

.main-order-details .order-details-header{
  border-bottom: 1px solid var(--color-gray-clickmix);
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  height: 85px;
}
.main-order-details .order-details-header>div{
  padding: 0.8rem 1.2rem;
}
.main-order-details .order-details-header .badge{
  font-size: 15px;
  padding: 5px 10px;
  border: solid 2px #fff;
  background-color: #0000000c;
  color: var(--color-dark-clickmix);
}

.main-order-details .order-details-header .badge div {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;
}

.main-order-details .order-details-header .status-change{
  display: flex;
  background: #fff;
  align-items: center;
  align-self: center;
}

.main-order-details .order-details-body{
  padding: 1rem;
  height: 60vh;
  overflow: auto;}

.main-order-details .order-details-body>div,
.main-order-details .order-details-body .order-history{
  max-width: 550px;
  min-width: 300px;
  margin: 0 auto;
}

.main-order-details .order-details-footer{
  padding: 1rem;
  border-top: 1px solid var(--color-bg-gray);
  display: flex;
  gap: 1rem;
}



/*---- ORDER MANAGER PAGE FOOTER -----*/

.main-footer-order-manager{
  height: 35px;
  width: 100%;
  padding: 5px 30px;
  background-color: var(--color-bg-gray-light-2);
}
.main-footer-order-manager .footer-left {
  float: left;
}
.main-footer-order-manager .footer-right {
  float: right;
}



/*---- ORDER MANGER ==== PRINT =====*/

@media screen {
  #printSection {
    display: none;
  }
  .no-screen{
    display: none;
  }
}


.manager-order-header .manager-order-header-info{
  display: grid;
  grid-template-columns: minmax(65px, 15%) minmax(80px, 15%) minmax(70px, 15%) minmax(100px, 50%);
  gap: 1rem;
  width: 100%;
}
.manager-order-header .manager-order-header-info label {
  font-size: 12px;
  margin-bottom: 0px;
}
.manager-order-header .manager-order-header-customer{
  display: grid;
  grid-template-columns: minmax(150px, 30%) minmax(100px, 50%);
  gap: 1rem;
  width: 100%;
}
.manager-order-header .manager-order-header-customer .order-name{
  font-size: 18px;
}
.manager-order-body {
  overflow: scroll;
  outline: none;
  cursor: grab;
  touch-action: none;
  padding: 1rem;
  height: 50vh;
}
.manager-order-footer {
  position: sticky!important;
  bottom: 0px;
}



.order-status-total{
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
}
.order-status-total>div{
  text-align: center;
}
.order-status-total h5{
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.order-status-total .order-status-total-01{
  line-height: 1.2!important
}


.list-orders-merchant .badge-order-status div{
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;
}
.list-orders-merchant h6 .badge {
  font-size: 12px!important;
}

.badge.badge-cancel{
  color: #cc170d;
  background-color: #5f5c5c27;
}


.list-orders-merchant .badge.badge-pendente{
  background-color: #DE6F00;
  color: #fff;
  -webkit-animation: sk-scaleout 1.5s ease-in-out infinite;
  animation: sk-scaleout 1.5s ease-in-out infinite;
}

.list-clickmix .font-mix-01,
.list-orders-merchant .order-font-1{
  font-size: 16px;
}

.list-clickmix .font-mix-02,
.list-orders-merchant .order-font-2{
  font-size: 15px;
}

.list-orders-merchant .payment-tag img{
  width: 28px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  margin-right: 3px;
}

.list-orders-merchant .order-costumer {
  position: relative;
}

.list-orders-merchant .order-costumer .badge-order-new {
  position: absolute;
  top: 50%;
  left: 10px;
  background-color: rgba(59, 165, 27, 0.87)!important;
  color: #fff;
}

.list-orders-merchant .order-costumer small,
.list-orders-merchant .order-costumer a:hover small{
  font-size: 90%;
  color: rgb(44, 42, 41);
}

.list-orders-merchant  .order-costumer a:hover{
  text-decoration: none;
  color: var(--color-primary-dark-clickmix)!important;
}

.list-orders-merchant .order-costumer-name,
.list-orders-merchant .order-costumer-phone{
  font-size: 12px!important;
  font-weight: 400;
  color: #60686f!important;
}

.list-orders-merchant .order-trans-type{
  font-size: 14px;
}

.list-orders-merchant td .btn-group .btn{
  padding: 0;
  color: var(--color-primary-clickmix);
}

.list-orders-merchant td .btn-group .btn .fas{
  font-size: 19px;
}

.list-orders-merchant td .btn-group a.dropdown-item {
  cursor: pointer;
}

.list-orders-merchant td .btn-group .dropdown-item:focus,
.list-orders-merchant td .btn-group .dropdown-item:hover{
  color: var(--color-primary-clickmix)!important;
}

.list-orders-merchant td .dropleft .dropdown-toggle::before{
  display: none;
}

.list-orders-merchant td .dropleft small{
  font-size: 70%;
  color: #9e9e9e;
  padding-left: 35px;
}

.list-orders-merchant .info-order-badge{
  font-size: 10px!important;
  background-color: rgba(255, 255, 255, 0.871);
  padding: 3px 5px!important;
  color: #000;
  margin: 0 0.6em;
}

.list-orders-merchant .badge-new{
  background-color: rgba(252, 92, 0, 0.753)!important;
  color: #fff!important;
}

.list-orders-merchant .badge-normal{
  background-color: rgba(201, 198, 192, 0.342)!important;
}

.list-orders-merchant .badge-order-origem{
  font-size: 11px;
}

@media (max-width: 780px){
  .list-orders-merchant .badge-order-origem small{
    display: none;
  }

}



/*==== 2 - MODAL UPLOAD ==== */

.modal-upload input[type="file"] {
  display: none;
}

.modal-upload .upload-label label {
  margin-bottom: 0px!important;
}


/*=== 3 - MODAL TICKET === */

.print-order{
  font-family: sans-serif, 'Courier New', Courier, monospace;
  width: 260px;
  margin: 0px;
}

.print-order hr{
  border: dashed 1px #000;
}



.modal-content{

}

.modal-ticket-form{
  margin-bottom: 0px!important;
}

.modal-ticket-form textarea ::placeholder{
  color: bisque;
}

.modal-order-edit .modal-header, .modal-order-edit .modal-body, .modal-order-edit .modal-footer{
  padding: 20px!important;
}

#order-ticket{
  width: 100% !important;
}

#order-ticket .printer-ticket{
  width: 100% !important;
}

#order-ticket ul{
  list-style: none;
  margin: 0px ;
  padding: 0px;
  line-height: 15pt !important;
}

#order-ticket .cart_iten {
  margin-bottom: 5pt;
}

#order-ticket .detail_cart{
  display: flex;
}

#order-ticket ul li span{
  float:right;
  font-weight:500;
  text-align: right;
}

#order-ticket .detail_cart .iten {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 160px;
}

#order-ticket .detail_cart .price {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 60px;
  font-weight: 600;
  text-align: right;
}

#order-ticket .detail_cart .normal-price {
  text-decoration: line-through;
  font-weight: 300;
}

#order-ticket .list_addons{
  border-left: 1px dotted #000;
  margin-left: 6pt;
  padding-left: 3pt;

}

#order-ticket .cart_iten_total{
  border-top: 1px dashed rgb(0, 0, 0);
  padding-top:4pt;
  line-height: 15pt !important;
}

#order-ticket .total{
  font-weight:700;
  text-transform:uppercase;}

#order-ticket .total span{
  font-weight:700 !important;
}



.order-modal-edit{
  position: relative;
}
.order-modal-edit .modal-body{
  min-height: 50vh;
  overflow: auto;
}

.order-modal-header{
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.order-modal-edit .order-modal-header-info{
  display: grid;
  grid-template-columns: minmax(60px, 80px) 80px 70px minmax(100px, 50%);
  gap: 1rem;
  width: 100%;

}
.order-modal-edit .modal-order-header-customer{
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
}

.order-modal-edit .order-modal-header-info label{
  font-size: 10px;
  margin-bottom: 0px;
}
.modal-footer-order {
  border-top: #c0c0c0;
}
.modal-footer-order .footer-options-01{
  display: grid;
  grid-template-columns:repeat(auto-fill,minmax(150px,200px));
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
.modal-footer-order .footer-options-status{
  display: grid;
  grid-template-columns: 40% 50%;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
}
.modal-footer-order .order-bnt-action{
  grid-template-columns: auto auto;
  gap: 1em;
  -webkit-box-align: center;
  align-items: center;
}

.modal-footer-order .order-bnt-action .fa-print,
.modal-footer-order .order-bnt-action .fa-times{
  display: none;
}

.btn-print-mobile{
  display: none!important;
}


@media (max-width: 575.98px) {
  .modal-footer-order .footer-options-status{
    grid-template-columns: auto;
  }
  .btn-print-mobile{
    display: block!important;
  }

  .btn-print-desktop{
    display: none!important;
  }
}




/*=== 4 - MODAL PRODUCT === */



.product-modal-scroll, .product-modal-scroll-2 {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0px;
  min-height: 65vh;
}

.product-modal-scroll-2{
  max-height: 65vh !important;
}

@media (max-width: 780px){
}

.fancybox-slide--iframe .fancybox-content {
  max-width: 100%;
  max-height: 85vh !important;
  margin: 0;
  padding:0;
}

.modal-food-item,
.modal-food-complementos,
.modal-food-addon {
  max-width: 900px;
  min-width: 900px;
}


@media screen and (max-width: 992px){
  .modal-food-item,
  .modal-food-complementos,
  .modal-food-addon{
    max-width: 800px;
    min-width: 800px;
  }}

@media screen and (max-width: 768px){
  .modal-food-item,
  .modal-food-complementos,
  .modal-food-addon {
    max-width: 700px;
    min-width: 300px;
    margin: 0.5rem;
  }}


.modal-body-opcoes-perguntas{
  min-height: 300px;
}

.modal-body-opcoes-perguntas .selectgroup-button{
  font-size: 16px;
  padding: 0 1.6rem;
}

.hr-addons{
  border-top: 4px solid rgba(0,0,0,.1) !important;
}

.product-li-addon-switch{
  position: absolute;
  top: 9px;
  right: 0px;
}

.product-li-addon-switch-th{
  position: absolute;
  top: 8px;
  right: 0px;
}

.food-list-img img {
  -webkit-box-shadow: 0 5px 15px 0 rgb(105 103 103 / 50%);
  box-shadow: 0 5px 15px 0 rgb(105 103 103 / 50%);
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 60px;
  margin: .5em;
}

.food-list-top-th th{
  height: 20px!important;
  padding: .5rem 10px!important;
  font-size: 12px;
}

.Selected + label{
  font-weight: bold !important;
}

#google_map_wrap{
  border:1px solid #E4E7EA;
  width:100%;
  height:300px;
  margin-top:10px;
}



/*=== 5 - VIEWS DIVS MIN MAX===*/

.min-vh-50{min-height:50vh !important}
.min-vh-75{min-height:75vh !important}
.min-vh-80{min-height:86Vh !important}

.min-height-50{min-height: 50px}
.min-height-70{min-height: 70px}
.min-height-80{min-height: 80px}
.min-height-90{min-height: 90px}
.min-height-100{min-height: 100px}
.min-height-110{min-height: 110px}
.min-height-120{min-height: 130px}
.min-height-140{min-height: 140px}
.min-height-150{min-height: 150px}
.min-height-160{min-height: 160px}
.min-height-170{min-height: 170px}
.min-height-180{min-height: 180px}
.min-height-190{min-height: 190px}
.min-height-200{min-height: 200px}
.min-height-210{min-height: 210px}
.min-height-220{min-height: 220px}
.min-height-230{min-height: 230px}
.min-height-240{min-height: 240px}
.min-height-250{min-height: 250px}
.min-height-260{min-height: 260px}
.min-height-270{min-height: 270px}
.min-height-280{min-height: 280px}
.min-height-290{min-height: 290px}
.min-height-300{min-height: 300px}
.min-height-310{min-height: 310px}


.min-width-form-50{min-width: 50px;max-width: 55px;}
.min-width-form-60{min-width: 60px;max-width: 65px;}
.min-width-form-70{min-width: 70px;max-width: 75px;}
.min-width-form-80{min-width: 80px;max-width: 85px;}
.min-width-form-90{min-width: 90px;max-width: 95px;}
.min-width-form-100{min-width: 100px;max-width: 105px;}
.min-width-form-110{min-width: 110px;max-width: 115px;}
.min-width-form-120{min-width: 120px;max-width: 125px;}
.min-width-form-130{min-width: 130px;max-width: 135px;}
.min-width-form-140{min-width: 140px;max-width: 145px;}
.min-width-form-150{min-width: 150px;max-width: 155px;}
.min-width-form-160{min-width: 160px;max-width: 165px;}
.min-width-form-170{min-width: 170px;max-width: 175px;}
.min-width-form-180{min-width: 180px;max-width: 185px;}
.min-width-form-190{min-width: 190px;max-width: 195px;}
.min-width-form-200{min-width: 200px;max-width: 205px;}
.min-width-form-210{min-width: 210px;max-width: 215px;}
.min-width-form-220{min-width: 220px;max-width: 225px;}
.min-width-form-230{min-width: 230px;max-width: 235px;}
.min-width-form-240{min-width: 240px;max-width: 245px;}
.min-width-form-250{min-width: 250px;max-width: 255px;}
.min-width-form-260{min-width: 260px;max-width: 265px;}
.min-width-form-270{min-width: 270px;max-width: 275px;}
.min-width-form-280{min-width: 280px;max-width: 285px;}
.min-width-form-290{min-width: 290px;max-width: 295px;}
.min-width-form-300{min-width: 300px;max-width: 305px;}
.min-width-form-310{min-width: 310px;max-width: 315px;}
.min-width-form-320{min-width: 320px;max-width: 325px;}
.min-width-form-330{min-width: 330px;max-width: 335px;}
.min-width-form-350{min-width: 350px;max-width: 355px;}
.min-width-form-360{min-width: 350px;max-width: 365px;}
.min-width-form-370{min-width: 360px;max-width: 375px;}
.min-width-form-380{min-width: 370px;max-width: 385px;}
.min-width-form-390{min-width: 380px;max-width: 395px;}
.min-width-form-400{min-width: 390px;max-width: 425px;}

.min-width-20{min-width: 20px}
.min-width-30{min-width: 30px}
.min-width-40{min-width: 40px}
.min-width-50{min-width: 50px}
.min-width-60{min-width: 60px}
.min-width-70{min-width: 70px}
.min-width-80{min-width: 80px}
.min-width-90{min-width: 90px}
.min-width-100{min-width: 100px}
.min-width-110{min-width: 110px}
.min-width-120{min-width: 120px}
.min-width-130{min-width: 130px}
.min-width-140{min-width: 140px}
.min-width-150{min-width: 150px}
.min-width-160{min-width: 160px}
.min-width-170{min-width: 170px}
.min-width-180{min-width: 180px}
.min-width-190{min-width: 190px}
.min-width-200{min-width: 200px}
.min-width-210{min-width: 210px}
.min-width-220{min-width: 220px}
.min-width-230{min-width: 230px}
.min-width-240{min-width: 240px}
.min-width-250{min-width: 250px}
.min-width-260{min-width: 260px}
.min-width-270{min-width: 270px}
.min-width-280{min-width: 280px}
.min-width-290{min-width: 290px}
.min-width-300{min-width: 300px}
.min-width-310{min-width: 310px}
.min-width-320{min-width: 320px}
.min-width-330{min-width: 330px}
.min-width-340{min-width: 340px}
.min-width-350{min-width: 350px}
.min-width-360{min-width: 360px}
.min-width-370{min-width: 370px}

.max-width-30{max-width: 30px}
.max-width-40{max-width: 40px}
.max-width-50{max-width: 50px}
.max-width-70{max-width: 70px}
.max-width-80{max-width: 80px}
.max-width-90{max-width: 90px}
.max-width-100{max-width: 100px}
.max-width-110{max-width: 110px}
.max-width-120{max-width: 120px}
.max-width-130{max-width: 130px}
.max-width-140{max-width: 140px}
.max-width-150{max-width: 150px}
.max-width-160{max-width: 160px}
.max-width-170{max-width: 170px}
.max-width-180{max-width: 180px}
.max-width-190{max-width: 190px}
.max-width-200{max-width: 200px}
.max-width-210{max-width: 210px}
.max-width-220{max-width: 220px}
.max-width-230{max-width: 230px}
.max-width-240{max-width: 240px}
.max-width-250{max-width: 250px}
.max-width-260{max-width: 260px}
.max-width-270{max-width: 270px}
.max-width-280{max-width: 280px}
.max-width-290{max-width: 290px}
.max-width-300{max-width: 300px}
.max-width-310{max-width: 310px}
.max-width-320{max-width: 320px}
.max-width-330{max-width: 330px}
/*=== TEXT LINE LIMIT CARACTERES ====*/



.text-hidden-line-1{
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;
}

.text-hidden-line-2{
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical!important;
}

/*=== 6 - FORMS CUSTOM ===*/



textarea.description-form{
  height: 150px!important;
  resize: none;
}



.form-group ::-webkit-input-placeholder { /* Edge */
  color:#c0c0c0!important;}

.form-group ::placeholder{
  color:#c0c0c0!important;
}



/*== 7 - SWITCH NORMAL ===*/

.switch-custom{
  position: relative;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  background: #bebcbc;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
  transition: .3s;
  cursor: pointer;
}

.switch-custom:checked{
  background: var(--bg-switch);
}

.switch-custom:before{
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: rgb(230, 225, 225);
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
  transition: .3s;
}

.switch-custom:checked:before{
  left: 20px;
}



/*--- MINI SWITCH --*/



.switch-custom-sm{
  position: relative;
  width: 36px;
  height: 18px;
  -webkit-appearance: none;
  background: var(--bg-pause);
  outline: none;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .25);
  transition: .3s;
  cursor: pointer;
}

.switch-custom-sm:checked{
  background: var(--bg-switch);
}

.switch-custom-sm:before{
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  transition: .3s;
}

.switch-custom-sm:checked:before{
  left: 18px;
}





/*=== 8 - SWITCH TEXT ===*/

.container-switch-text{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 130px;
  height: 23px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.096);
  overflow: hidden;
  cursor: pointer;
}



.switch-custom-text{
  -webkit-appearance: none;
}

.switch-custom-text-label{
  display: block;
  height: 120px;
  width: 50%;
  background-size: 110px 140px;
  background: linear-gradient(
    to bottom,
    white 0, white 90px,
    var(--bg-switch) 90px, var(--bg-switch) 180px
  );
  position: absolute;
  top: 0;
  transition: .3s;
  color: #7e7e7e;
  cursor: pointer;
}



.switch-custom-text-label:nth-of-type(2){
  right: 0;
}

.switch-custom-text-label span{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 100%;
  text-align: center;
  height: 23px;
}

.switch-custom-text:checked + .switch-custom-text-label{
  background-position: 0 -90px;
  color: white;
}





/*=== 9 - LISTA DE PRODUTOS E COMPLEMENTOS ===*/

.category-title-tbody{

}



.search-group-product{
  position: sticky;
  top: 68px;
  z-index: 10;
  background: #fff;
  padding: 20px 0;
  margin-bottom: 20px;
  box-shadow: 0 25px 15px -30px rgba(0, 0, 0, 0.15);
}

.search-group-product .search-group-stick{
  box-shadow: 0 15px 25px -20px rgba(0, 0, 0, 0.25);
}

.search-group-product .form-group{
  margin: 0;
}

.category-title-product{
  height: 60px;
  position: relative;
}

.category-title-product a .h4{
  color: #212529!important;
  font-weight: 700;
}

.category-title-product a .h4:hover,
.product-list-row .product-list-title a:hover,
.addons-list-row .addons-list-title a:hover{
  color: #ce6700!important;
}

.category-title-product a{
  text-decoration: none;
}

.category-title-product .category-badge{
  top:25%;
  position: absolute;
  right: 400px;
  font-size: 11px!important;
  background-color: rgba(255, 255, 255, 0.871);
}

.product-list-title .product-badge-group{
  top:5%;
  position: absolute;
  right: 0px;
}

.product-list-title .info-product-badge{
  font-size: 9px!important;
  background-color: rgba(255, 255, 255, 0.871);
  padding: 3px 5px!important;
  color: #000;
  margin: 0 0.6em;
}

.product-list-title .badge-promo{
  background-color: rgba(144, 238, 144, 0.432)!important;
}

.product-list-title .badge-progr{
  background-color: rgba(144, 196, 238, 0.432)!important;
}

.product-list-title .badge-addons{
  background-color: rgba(241, 160, 53, 0.32)!important;
}

.badge-price-addons{
  font-size: 9px!important;
  border: solid 1px #f0f0f0;
  background-color: #fff;
  text-transform: uppercase!important;
}

.category-addon-btn .btn-sm{
  font-size: 11px!important;
  text-transform: uppercase!important;
  line-height: 21px!important;
  min-width: 120px;
}

.category-product-btn .btn-sm{
  font-size: 11px!important;
  text-transform: uppercase!important;
  line-height: 21px!important;
  min-width: 90px;
}



.category-group-list th{
  font-size: 12px;
  text-transform: uppercase;
}



.product-list-row .product-list-title,
.addons-list-row .addons-list-title{
  font-size: 16px;
}

.product-list-row .product-list-title a:hover,
.title-list a:hover,
.addons-list-row .addons-list-title a:hover{
  text-decoration: none;
}

.table-hover .product-list-row:hover,

.addons-list-row tr:hover{
  background-color: rgba(0, 0, 0, 0.034)!important;
}

.product-price-item .normal-price{
  text-decoration: line-through;
  color:#c0c0c0 !important;
}

.product-price-item span small{
  color:#c0c0c0 !important;
}


.product-list-row,
.product-list-row td,
.product-list-row .table:not(.table-sm):not(.table-md):not(.dataTable) td,
.product-list-row .table:not(.table-sm):not(.table-md):not(.dataTable) th{
  height: 75px!important;
}

.row-space-group tr td{
  height: 60px;
  border-left: none;
  border-right: none;
}

.image-preview-div{
  width: 180px;
  height: 180px;
}

.image-preview-food{
  background-image: url(https://dev.clickmix.net/protected/modules/singlemerchant/assets/images/default-logo.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.image-preview .image-preview-options:hover{
  opacity: 1;
}

.image-preview .image-preview-options{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: opacity .2s linear;
  opacity: 0;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview .image-options-div{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex: 1 1;
  flex: 1 1;
}

.image-preview .image-options-div:hover {
  background-color: #FBFAF8;
}


/*--- PAUSE CLASS ---*/

.category-pause .bg-light-2{
  background-color: #f0f0f0!important;
  background: #f0f0f0!important;
}

.category-pause,
.category-pause .btn,
.category-pause .category-edit-del a,
.category-pause .category-title-product a .h4,
.category-pause .text-muted,
.category-group-pause tr,
.category-group-pause td,
.category-group-pause .product-list-title a span,
.category-group-pause .product-list-title a,
.category-group-pause .addons-list-title a span,
.category-group-pause .addons-list-title a,
.product-list-pause,
.product-list-pause .product-list-title a,
.product-list-pause .product-list-title .text-muted,
.product-list-pause .product-list-row .product-list-title a,
.product-list-pause .product-list-row .product-list-title a:hover,
.product-list-pause .addons-list-title a,
.product-list-pause .addons-list-title .text-muted,
.product-list-pause .addons-list-row .addons-list-title a,
.product-list-pause .addons-list-row .addons-list-title a:hover,
.product-list-pause-addon label{
  color: #c0c0c0 !important;
}

.product-list-title a:hover .text-muted{
  text-decoration: none!important;
}

.category-pause .category-title-product .btn-outline-success:hover,

.category-pause .category-addon-btn .btn-outline-success:hover{
  color: #fff!important;
}

.category-pause .category-title-product .btn-outline-success{
  border-color: #c0c0c0 ;
}

.product-list-pause img, .category-group-pause img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
}

.category-pause .switch-custom-text-label,
.product-list-pause .switch-custom-text-label
{
  background: linear-gradient(
    to bottom,
    white 0, white 90px,
    #c0c0c0 90px, #c0c0c0 180px
  );
}


/*=== 10 CARDS AJUSTES =====*/

.card-clickmix .card-header h4{
  display: flex;
  align-items: center;
  margin: .2rem 0;
}
.card-clickmix .card-header h4 .feather{
  margin-right: 10px;
  text-align: center;
  fill: var(--color-bg-gray-light-2);
}



.card-settings .card-header h4{
  display: flex;
  align-items: center;
}
.card-settings .card-header h4 span{
  margin-top: 3px;
}
.card-settings .card-header h4 .feather{
  margin-right: 10px;
  text-align: center;
  fill: rgba(75,75,90,0.12);
}
.card-footer-float{
  position: sticky!important;
  bottom:0px;
}

.badge-outline {
  display: inline-block;
  padding: 5px 8px;
  line-height: 12px;
  border: 1px solid;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 13px;
}

.text-dark{
  color: #000!important;
}
.text-clickmix{
  color: var(--color-primary-clickmix)!important;
}


/*=== 11 DASHBOARD ====*/

.initial-settings .card-config .card-body{
  min-height: 170px;
}

.initial-settings .card-config{
  max-width: 250px;
  min-width: 220px;
}

.initial-settings .card-config .fas,
.initial-settings .card-config .far,
.initial-settings .card-config .fab,
.initial-settings .card-config .fal{
  font-size: 39px;
  margin-bottom: 1rem;
}


/*=== 12 MENUS ===*/

.sidebar-mini .main-sidebar .sidebar-merchant-info .badge-merchant-open,
.sidebar-mini .main-sidebar .btn-opensite span,
.sidebar-mini .main-sidebar .merchant-hours,
.main-sidebar .sidebar-merchant-info .badge-merchant-open-icon,
.main-sidebar .buttons {
  display: none;
}

.sidebar-mini .main-sidebar .badge-merchant-open-icon,
.sidebar-mini .main-sidebar .buttons{
  display: block;
}

.sidebar-mini .main-sidebar .badge-merchant-open-icon i{
  font-size: 20px;
}

.merchant-title-panel{
  display: flex;
  align-items: center;
}

.merchant-title-panel a{
  display: flex;
  align-items: center;
}

.merchant-title-panel a:hover{
  text-decoration: none;
  color: var(--color-dark-clickmix);
}

@media screen and (max-width: 768px){
  .merchant-title-panel{
    display: none!important;
  }}

/*==== INTEGRATED CARDS =====*/



.integrated-section .row{

}
.integrated-section .card{
  min-width: 290px;
  position: relative;
}
.integrated-section .card-body{
  min-height: 190px;
}
.integrated-section .card img{
  align-content: center;
  justify-content: center;
  max-width: 200px;
  max-height: 115px;
  min-height: 115px;
  margin-top: 1rem;
}
.integrated-section .card .badge{
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
}

.integrated-section .card .badge-active{
  background-color: rgba(43, 182, 43, 0.807)!important;
}



/*==== PLANOS CARDS =====*/

.planos-card .planos-detalhes{
  min-height: 250px;
}

.planos-card .planos-detalhes{
  text-align: left;
  display: inline-block;
}

.planos-card .planos-itens-list{
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.planos-card .planos-itens-list li{
  position: relative;
  display: block;
  padding: .15rem .3rem;
  margin-bottom: -1px;
}

.planos-card .planos-itens {
  display: flex;
  margin-bottom: 15px;
}

.planos-card .planos-preco{
  min-height: 100px;
}

.planos-card .planos-detalhes .planos-itens-icon{
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: #54ca68;
  color: #fff;
  margin-right: 10px;
}

.planos-card .planos-topo{
  min-height: 50px;
}

.planos-card .planos-ativo{
  background-color: #E4E7EA;
  color: #000;
}

.planos-card .planos-rodape{
  text-align: center;
  margin-top: 15px;
  min-height: 40px;
}

.planos-card-assinar {
  min-width: 220px;
  background-color: #fff;
  margin-bottom: 25px;
}

.planos-carrinho{
  max-width: 330px;
}

.planos-carrinho .carrinho-detalhes {
  background-color: #f7f7f7;
  padding: 8px 0;
}

.planos-carrinho .carrinho-detalhes hr{
  border: 1px dashed #e0e0e0;
  margin: 5px 0;
}

.planos-carrinho .carrinho-detalhes p{
  margin: 0;
  padding: 0 15px;
}

.planos-carrinho .carrinho-detalhes p span{
  float: right;
}

.planos-card-servicos{
  background-color: #fff;
  margin-bottom: 25px;
}


/*---- 14 divs / Ajustes Gerais ----*/

@media (max-width: 524px){

  .main-content {
    padding-left: 15px;
    padding-right: 15px;
    width: 100% !important;
  }

}

/*---- MERCHANT DELIVERY REPORTS -----*/

.modal-body-invoice{
  max-height: 80vh;
  overflow-y: auto;
}


/*---- MERCHANT SETTINGS - INFO ----- */



.settings-logo-img .image-preview, #callback-preview, .settings-logo-img img{
  width: 150px!important;
  height: 150px!important;
}
.settings-background-img .image-preview, #callback-preview, .image-preview-banner img{
  width: 278px!important;
  height: 150px!important;
}

.settings-qrcode .qr-code-img, .qr-code-img canvas{
  width: 150px!important;
  height: 150px!important;
  padding: 4px;
}
.settings-qrcode .qr-code-img img{
  width: 140px!important;
  height: 140px!important;
}
